import Hero from '../components/Hero';
import StatsSection from '../components/StatsSection';
import FeaturesSection from '../components/FeaturesSection';
import CaseStudiesSection from '../components/CaseStudiesSection';

const Home = () => {
  return (
    <main>
      <Hero />
      <StatsSection />
      <FeaturesSection />
      <CaseStudiesSection />
    </main>
  );
};

export default Home;