import { ArrowRight } from 'lucide-react';

// Platform Features Illustration
const PlatformSVG = () => (
  <svg
    viewBox="0 0 400 300"
    className="w-full h-full"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <defs>
      <linearGradient id="platform-gradient" x1="0" y1="0" x2="1" y2="1">
        <stop offset="0%" stopColor="#3B82F6" stopOpacity="0.2" />
        <stop offset="100%" stopColor="#60A5FA" stopOpacity="0.1" />
      </linearGradient>
    </defs>

    {/* Platform Interface */}
    <rect
      x="50"
      y="50"
      width="300"
      height="200"
      rx="10"
      fill="url(#platform-gradient)"
      className="animate-pulse-slow"
    />

    {/* Dashboard Elements */}
    <g className="animate-fade-in delay-2">
      {/* Sidebar */}
      <rect x="60" y="60" width="60" height="180" fill="#3B82F6" fillOpacity="0.1" />
      <circle cx="90" cy="85" r="10" fill="#3B82F6" fillOpacity="0.3" />
      <circle cx="90" cy="125" r="10" fill="#3B82F6" fillOpacity="0.3" />
      <circle cx="90" cy="165" r="10" fill="#3B82F6" fillOpacity="0.3" />

      {/* Main Content */}
      <rect x="130" y="70" width="210" height="80" rx="5" fill="#3B82F6" fillOpacity="0.1" />
      <rect x="130" y="160" width="100" height="70" rx="5" fill="#3B82F6" fillOpacity="0.1" />
      <rect x="240" y="160" width="100" height="70" rx="5" fill="#3B82F6" fillOpacity="0.1" />

      {/* Data Points */}
      <g className="animate-pulse">
        <circle cx="150" cy="90" r="4" fill="#3B82F6" />
        <line x1="160" y1="90" x2="320" y2="90" stroke="#3B82F6" strokeWidth="1" />
        <circle cx="150" cy="110" r="4" fill="#3B82F6" />
        <line x1="160" y1="110" x2="280" y2="110" stroke="#3B82F6" strokeWidth="1" />
        <circle cx="150" cy="130" r="4" fill="#3B82F6" />
        <line x1="160" y1="130" x2="300" y2="130" stroke="#3B82F6" strokeWidth="1" />
      </g>
    </g>
  </svg>
);

// API Integration Illustration
const APISVG = () => (
  <svg
    viewBox="0 0 400 300"
    className="w-full h-full"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <defs>
      <linearGradient id="api-gradient" x1="0" y1="0" x2="1" y2="1">
        <stop offset="0%" stopColor="#3B82F6" stopOpacity="0.1" />
        <stop offset="100%" stopColor="#60A5FA" stopOpacity="0.2" />
      </linearGradient>
    </defs>

    {/* Connection Lines */}
    <g className="animate-draw">
      <path
        d="M100 150 C150 150, 150 100, 200 100 M200 100 C250 100, 250 150, 300 150"
        stroke="#3B82F6"
        strokeWidth="2"
        strokeDasharray="5,5"
      />
      <path
        d="M100 150 C150 150, 150 200, 200 200 M200 200 C250 200, 250 150, 300 150"
        stroke="#3B82F6"
        strokeWidth="2"
        strokeDasharray="5,5"
      />
    </g>

    {/* API Endpoints */}
    <g className="animate-fade-in delay-2">
      {/* Left System */}
      <rect x="50" y="120" width="100" height="60" rx="5" fill="#3B82F6" fillOpacity="0.1" />
      <text x="70" y="155" fill="#3B82F6" fontSize="12">Your System</text>

      {/* Center API */}
      <rect x="175" y="70" width="50" height="160" rx="5" fill="url(#api-gradient)" />
      <text x="180" y="140" fill="#3B82F6" fontSize="12" transform="rotate(-90 180,140)">JaanchAI API</text>

      {/* Right System */}
      <rect x="250" y="120" width="100" height="60" rx="5" fill="#3B82F6" fillOpacity="0.1" />
      <text x="270" y="155" fill="#3B82F6" fontSize="12">AI Engine</text>
    </g>

    {/* Data Flow */}
    <g className="animate-pulse">
      <circle cx="150" cy="100" r="4" fill="#3B82F6" />
      <circle cx="250" cy="200" r="4" fill="#3B82F6" />
      <circle cx="200" cy="150" r="6" fill="#3B82F6" />
    </g>
  </svg>
);

// Enterprise Solutions Illustration
const EnterpriseSVG = () => (
  <svg
    viewBox="0 0 400 300"
    className="w-full h-full"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <defs>
      <linearGradient id="enterprise-gradient" x1="0" y1="0" x2="1" y2="1">
        <stop offset="0%" stopColor="#3B82F6" stopOpacity="0.3" />
        <stop offset="100%" stopColor="#60A5FA" stopOpacity="0.1" />
      </linearGradient>
    </defs>

    {/* Network Grid */}
    <path
      d="M50 50 H350 M50 150 H350 M50 250 H350 M150 50 V250 M250 50 V250"
      stroke="#3B82F6"
      strokeWidth="0.5"
      strokeOpacity="0.2"
    />

    {/* Connection Points */}
    <g className="animate-fade-in">
      {[...Array(9)].map((_, i) => {
        const x = 150 + (i % 3 - 1) * 100;
        const y = 150 + (Math.floor(i / 3) - 1) * 100;
        return (
          <g key={i}>
            <circle cx={x} cy={y} r="20" fill="url(#enterprise-gradient)" />
            <circle cx={x} cy={y} r="5" fill="#3B82F6" className="animate-pulse" />
          </g>
        );
      })}
    </g>

    {/* Connection Lines */}
    <g className="animate-draw delay-2" stroke="#3B82F6" strokeWidth="1">
      <line x1="150" y1="150" x2="250" y2="150" />
      <line x1="150" y1="50" x2="150" y2="250" />
      <line x1="250" y1="50" x2="250" y2="250" />
      <line x1="150" y1="50" x2="250" y2="50" />
      <line x1="150" y1="250" x2="250" y2="250" />
    </g>
  </svg>
);

const Products = () => {
  const products = [
    {
      id: 'platform',
      title: 'JaanchAI Platform',
      description: 'Our comprehensive healthcare intelligence platform that brings together AI diagnostics, patient management, and clinical workflows.',
      illustration: <PlatformSVG />,
      features: [
        'Intuitive dashboard interface',
        'Real-time analytics',
        'Customizable workflows',
        'Integrated patient records'
      ]
    },
    {
      id: 'api',
      title: 'API Access',
      description: 'Seamlessly integrate our AI capabilities into your existing healthcare systems with our robust API.',
      illustration: <APISVG />,
      features: [
        'RESTful API architecture',
        'Secure data transmission',
        'Comprehensive documentation',
        'Developer support'
      ]
    },
    {
      id: 'enterprise',
      title: 'Enterprise Solutions',
      description: 'Custom-built solutions for large healthcare organizations with specific needs and complex workflows.',
      illustration: <EnterpriseSVG />,
      features: [
        'Custom implementation',
        'Dedicated support team',
        'System integration',
        'Scalable infrastructure'
      ]
    }
  ];

  return (
    <div className="min-h-screen bg-black">
      {/* Hero Section */}
      <div className="pt-32 pb-16 px-4 sm:px-6 lg:px-8">
        <div className="max-w-7xl mx-auto">
          <div className="text-center">
            <h1 className="text-4xl font-bold tracking-tight text-white sm:text-5xl">
              Products
              <span className="block mt-2 gradient-text">Built for Healthcare</span>
            </h1>
            <p className="mt-6 text-xl text-gray-400 max-w-2xl mx-auto">
              Choose the right solution for your healthcare organization
            </p>
          </div>
        </div>
      </div>

      {/* Products Grid */}
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-16">
        <div className="space-y-32">
          {products.map((product) => (
            <section
              key={product.id}
              id={product.id}
              className="scroll-mt-24"
            >
              <div className="grid lg:grid-cols-2 gap-16 items-center">
                <div className="space-y-8">
                  <div>
                    <h2 className="text-3xl font-bold text-white mb-4">
                      {product.title}
                    </h2>
                    <p className="text-xl text-gray-400">
                      {product.description}
                    </p>
                  </div>
                  <ul className="space-y-4">
                    {product.features.map((feature, index) => (
                      <li key={index} className="flex items-center text-gray-300">
                        <div className="w-1.5 h-1.5 rounded-full bg-blue-500 mr-2" />
                        {feature}
                      </li>
                    ))}
                  </ul>
                  <button className="inline-flex items-center px-6 py-3 rounded-lg bg-blue-600 text-white hover:bg-blue-700 transition-colors">
                    Learn more
                    <ArrowRight className="ml-2 h-4 w-4" />
                  </button>
                </div>
                <div className="glass rounded-2xl p-8 h-[400px] hover:bg-white/10 transition-colors">
                  {product.illustration}
                </div>
              </div>
            </section>
          ))}
        </div>
      </div>

      {/* CTA Section */}
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-16">
        <div className="relative overflow-hidden bg-gradient-to-r from-blue-600 to-blue-700 rounded-2xl p-8 md:p-16 text-center">
          <div className="relative z-10">
            <h2 className="text-3xl font-bold text-white mb-4">
              Ready to get started?
            </h2>
            <p className="text-blue-100 mb-8 max-w-2xl mx-auto">
              Contact our team to find the right product for your organization.
            </p>
            <button className="bg-white text-blue-600 px-8 py-3 rounded-lg font-semibold hover:bg-blue-50 transition-colors">
              Contact Sales
            </button>
          </div>
          <div className="absolute inset-0 opacity-10 bg-[radial-gradient(circle_at_30%_50%,rgba(255,255,255,0.2),transparent_50%)]" />
        </div>
      </div>
    </div>
  );
};

export default Products;