import { Link } from 'react-router-dom';
import { Twitter, Linkedin, Github } from 'lucide-react';
import Logo from './Logo';

const Footer = () => {
  const navigation = {
    product: [
      { name: 'Features', href: '/features' },
      { name: 'AI Technology', href: '/technology' },
      { name: 'Security', href: '/security' },
      { name: 'Roadmap', href: '/roadmap' }
    ],
    solutions: [
      { name: 'Diagnostics', href: '/solutions/diagnostics' },
      { name: 'Patient Care', href: '/solutions/patient-care' },
      { name: 'Clinical Research', href: '/solutions/research' },
      { name: 'Hospital Operations', href: '/solutions/operations' }
    ],
    resources: [
      { name: 'Documentation', href: '/docs' },
      { name: 'Case Studies', href: '/case-studies' },
      { name: 'API Reference', href: '/api' },
      { name: 'Healthcare Blog', href: '/blog' }
    ],
    company: [
      { name: 'About', href: '/about' },
      { name: 'Careers', href: '/careers' },
      { name: 'Partners', href: '/partners' },
      { name: 'Contact', href: '/contact' }
    ]
  };

  return (
    <footer className="bg-black text-white" aria-labelledby="footer-heading">
      <h2 id="footer-heading" className="sr-only">Footer</h2>
      
      <div className="mx-auto max-w-7xl px-6 pb-8 pt-16 sm:pt-24 lg:px-8">
        <div className="xl:grid xl:grid-cols-3 xl:gap-8">
          <div className="space-y-8">
            <div className="flex items-center">
              <Logo />
            </div>
            <p className="text-sm leading-6 text-gray-300">
              Transforming healthcare through intelligent automation and AI-powered diagnostics.
            </p>
            <div className="flex space-x-6">
              <a href="https://x.com" target="_blank" rel="noopener noreferrer" className="text-gray-400 hover:text-gray-300">
                <span className="sr-only">X (Twitter)</span>
                <Twitter className="h-6 w-6" />
              </a>
              <a href="https://linkedin.com" target="_blank" rel="noopener noreferrer" className="text-gray-400 hover:text-gray-300">
                <span className="sr-only">LinkedIn</span>
                <Linkedin className="h-6 w-6" />
              </a>
              <a href="https://github.com" target="_blank" rel="noopener noreferrer" className="text-gray-400 hover:text-gray-300">
                <span className="sr-only">GitHub</span>
                <Github className="h-6 w-6" />
              </a>
            </div>
          </div>

          {/* Navigation Grid */}
          <div className="mt-16 grid grid-cols-2 gap-8 xl:col-span-2 xl:mt-0">
            <div className="md:grid md:grid-cols-2 md:gap-8">
              <div>
                <h3 className="text-sm font-semibold leading-6 text-white">Product</h3>
                <ul className="mt-6 space-y-4">
                  {navigation.product.map((item) => (
                    <li key={item.name}>
                      <Link
                        to={item.href}
                        className="text-sm leading-6 text-gray-300 hover:text-white transition duration-200"
                      >
                        {item.name}
                      </Link>
                    </li>
                  ))}
                </ul>
              </div>
              <div className="mt-10 md:mt-0">
                <h3 className="text-sm font-semibold leading-6 text-white">Solutions</h3>
                <ul className="mt-6 space-y-4">
                  {navigation.solutions.map((item) => (
                    <li key={item.name}>
                      <Link
                        to={item.href}
                        className="text-sm leading-6 text-gray-300 hover:text-white transition duration-200"
                      >
                        {item.name}
                      </Link>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
            <div className="md:grid md:grid-cols-2 md:gap-8">
              <div>
                <h3 className="text-sm font-semibold leading-6 text-white">Resources</h3>
                <ul className="mt-6 space-y-4">
                  {navigation.resources.map((item) => (
                    <li key={item.name}>
                      <Link
                        to={item.href}
                        className="text-sm leading-6 text-gray-300 hover:text-white transition duration-200"
                      >
                        {item.name}
                      </Link>
                    </li>
                  ))}
                </ul>
              </div>
              <div className="mt-10 md:mt-0">
                <h3 className="text-sm font-semibold leading-6 text-white">Company</h3>
                <ul className="mt-6 space-y-4">
                  {navigation.company.map((item) => (
                    <li key={item.name}>
                      <Link
                        to={item.href}
                        className="text-sm leading-6 text-gray-300 hover:text-white transition duration-200"
                      >
                        {item.name}
                      </Link>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        </div>

        {/* Newsletter Section */}
        <div className="mt-16 border-t border-white/10 pt-8 sm:mt-20 lg:mt-24">
          <div className="flex flex-col md:flex-row justify-between gap-8">
            <div className="flex-1 max-w-md">
              <h3 className="text-sm font-semibold leading-6 text-white">
                Subscribe to our newsletter
              </h3>
              <p className="mt-2 text-sm leading-6 text-gray-300">
                Get the latest updates on AI in healthcare and product releases.
              </p>
              <div className="mt-4 flex max-w-md">
                <label htmlFor="email-address" className="sr-only">
                  Email address
                </label>
                <input
                  type="email"
                  name="email-address"
                  id="email-address"
                  autoComplete="email"
                  required
                  className="min-w-0 flex-auto rounded-l bg-white/5 px-4 py-2 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-blue-500 sm:text-sm sm:leading-6"
                  placeholder="Enter your email"
                />
                <button
                  type="submit"
                  className="flex-none rounded-r bg-blue-500 px-4 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-blue-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-500"
                >
                  Subscribe
                </button>
              </div>
            </div>

            {/* Compliance Badges */}
            <div className="flex gap-4">
              <div className="h-12 w-12 rounded bg-white/5 flex items-center justify-center">
                <span className="text-xs text-gray-400">HIPAA</span>
              </div>
              <div className="h-12 w-12 rounded bg-white/5 flex items-center justify-center">
                <span className="text-xs text-gray-400">SOC 2</span>
              </div>
              <div className="h-12 w-12 rounded bg-white/5 flex items-center justify-center">
                <span className="text-xs text-gray-400">ISO</span>
              </div>
            </div>
          </div>

          {/* Bottom Bar */}
          <div className="mt-8 border-t border-white/10 pt-8 md:flex md:items-center md:justify-between">
            <div className="flex space-x-6 md:order-2">
              <Link to="/privacy" className="text-xs leading-6 text-gray-400 hover:text-white">
                Privacy Policy
              </Link>
              <Link to="/terms" className="text-xs leading-6 text-gray-400 hover:text-white">
                Terms of Service
              </Link>
              <Link to="/security" className="text-xs leading-6 text-gray-400 hover:text-white">
                Security
              </Link>
            </div>
            <p className="mt-8 text-xs leading-6 text-gray-400 md:order-1 md:mt-0">
              &copy; {new Date().getFullYear()} JaanchAI. All rights reserved.
            </p>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
