import { useState, useEffect } from 'react';
import { ArrowRight } from 'lucide-react';
import { LineChart, Line, AreaChart, Area, ResponsiveContainer, XAxis, YAxis, Tooltip } from 'recharts';

const Hero = () => {
  // If we need mounting animations
  const [mounted, setMounted] = useState(false);

  useEffect(() => {
    setMounted(true);
  }, []);

  // Diagnostic accuracy improvement over time
  const accuracyData = [
    { month: 'Jan', traditional: 76, withAI: 89 },
    { month: 'Feb', traditional: 75, withAI: 91 },
    { month: 'Mar', traditional: 78, withAI: 92 },
    { month: 'Apr', traditional: 74, withAI: 94 },
    { month: 'May', traditional: 76, withAI: 95 },
    { month: 'Jun', traditional: 77, withAI: 96 },
  ];

  // Patient wait time reduction
  const waitTimeData = [
    { time: '8am', before: 45, after: 12 },
    { time: '10am', before: 65, after: 18 },
    { time: '12pm', before: 85, after: 25 },
    { time: '2pm', before: 55, after: 15 },
    { time: '4pm', before: 40, after: 10 },
    { time: '6pm', before: 30, after: 8 },
  ];

  return (
    <div className="min-h-[90vh] flex items-center justify-center relative bg-black text-white">
      {/* Grid background */}
      <div 
        className={`absolute inset-0 opacity-[0.15] transition-opacity duration-1000 ${
          mounted ? 'opacity-[0.15]' : 'opacity-0'
        }`}
        style={{
          backgroundImage: `url("data:image/svg+xml,%3Csvg width='60' height='60' viewBox='0 0 60 60' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='none' fill-rule='evenodd'%3E%3Cg fill='%23ffffff' fill-opacity='0.4'%3E%3Cpath d='M36 34v-4h-2v4h-4v2h4v4h2v-4h4v-2h-4zm0-30V0h-2v4h-4v2h4v4h2V6h4V4h-4zM6 34v-4H4v4H0v2h4v4h2v-4h4v-2H6zM6 4V0H4v4H0v2h4v4h2V6h4V4H6z'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E")`,
        }}
      />

      <div className="relative max-w-screen-xl mx-auto px-4 sm:px-6 lg:px-8 pt-16 pb-24">
        <div className="grid lg:grid-cols-2 gap-12 items-center">
          <div className={`space-y-8 transition-all duration-1000 ${mounted ? 'translate-y-0 opacity-100' : 'translate-y-4 opacity-0'}`}>
            <div className="inline-block">
              <span className="inline-flex items-center px-3 py-1 text-xs font-medium border border-white/10 rounded-full bg-white/5 backdrop-blur-sm">
                <span className="w-1 h-1 rounded-full bg-blue-500 mr-2 animate-pulse" />
                AI-Powered Healthcare Analytics
              </span>
            </div>

            <h1 className="text-4xl md:text-5xl font-bold tracking-tight">
              Diagnostic accuracy{' '}
              <span className="text-transparent bg-clip-text bg-gradient-to-r from-blue-400 to-blue-600">
                amplified by AI
              </span>
            </h1>

            <p className="text-lg text-gray-400 leading-relaxed">
              Our AI analyzes patterns across millions of patient records, enhancing diagnostic accuracy from 75% to 95% while reducing decision time by 25%.
            </p>

            <div className="flex items-center gap-4">
              <button
                className="group px-6 py-2.5 rounded border border-white/10 bg-white/5 hover:bg-white/10 backdrop-blur-sm transition-all duration-300 flex items-center gap-2"
              >
                Start free trial
                <ArrowRight className="w-4 h-4 group-hover:translate-x-0.5 transition-transform" />
              </button>
              <div className="h-10 w-px bg-white/10" />
              <button className="text-sm text-gray-400 hover:text-white transition-colors">
                View live demo →
              </button>
            </div>
          </div>

          <div className={`space-y-8 transition-all duration-1000 delay-300 ${mounted ? 'translate-y-0 opacity-100' : 'translate-y-4 opacity-0'}`}>
            {/* Diagnostic Accuracy Chart */}
            <div className="p-4 rounded-lg border border-white/10 bg-white/5 backdrop-blur-sm">
              <h3 className="text-sm font-medium text-gray-400 mb-4">Diagnostic Accuracy Improvement</h3>
              <div className="h-64">
                <ResponsiveContainer width="100%" height="100%">
                  <AreaChart data={accuracyData}>
                    <defs>
                      <linearGradient id="gradientAI" x1="0" y1="0" x2="0" y2="1">
                        <stop offset="0%" stopColor="#3B82F6" stopOpacity={0.3} />
                        <stop offset="100%" stopColor="#3B82F6" stopOpacity={0} />
                      </linearGradient>
                    </defs>
                    <XAxis dataKey="month" stroke="#6B7280" />
                    <YAxis stroke="#6B7280" />
                    <Tooltip 
                      contentStyle={{ 
                        background: 'rgba(0, 0, 0, 0.8)',
                        border: 'none',
                        borderRadius: '4px',
                        color: '#fff'
                      }}
                    />
                    <Area
                      type="monotone"
                      dataKey="traditional"
                      stroke="#6B7280"
                      strokeWidth={2}
                      fill="none"
                    />
                    <Area
                      type="monotone"
                      dataKey="withAI"
                      stroke="#3B82F6"
                      strokeWidth={2}
                      fill="url(#gradientAI)"
                    />
                  </AreaChart>
                </ResponsiveContainer>
              </div>
            </div>

            {/* Wait Time Reduction Chart */}
            <div className="p-4 rounded-lg border border-white/10 bg-white/5 backdrop-blur-sm">
              <h3 className="text-sm font-medium text-gray-400 mb-4">Average Wait Time Reduction</h3>
              <div className="h-64">
                <ResponsiveContainer width="100%" height="100%">
                  <LineChart data={waitTimeData}>
                    <XAxis dataKey="time" stroke="#6B7280" />
                    <YAxis stroke="#6B7280" />
                    <Tooltip 
                      contentStyle={{ 
                        background: 'rgba(0, 0, 0, 0.8)',
                        border: 'none',
                        borderRadius: '4px',
                        color: '#fff'
                      }}
                    />
                    <Line
                      type="monotone"
                      dataKey="before"
                      stroke="#6B7280"
                      strokeWidth={2}
                      dot={false}
                    />
                    <Line
                      type="monotone"
                      dataKey="after"
                      stroke="#3B82F6"
                      strokeWidth={2}
                      dot={false}
                    />
                  </LineChart>
                </ResponsiveContainer>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Hero;