import { Link, useNavigate } from 'react-router-dom';
import { 
  ArrowRight, 
  FileText, 
  BookOpen, 
  Code, 
  Calendar,
  TrendingUp,
  Users,
  Shield
} from 'lucide-react';

// Case Study Card SVG
const CaseStudyIllustration = () => (
  <svg
    viewBox="0 0 200 160"
    className="w-full h-full"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <defs>
      <linearGradient id="statsGradient" x1="0" y1="0" x2="1" y2="1">
        <stop offset="0%" stopColor="#3B82F6" stopOpacity="0.2" />
        <stop offset="100%" stopColor="#60A5FA" stopOpacity="0.1" />
      </linearGradient>
    </defs>
    
    {/* Graph Background */}
    <rect x="20" y="20" width="160" height="120" rx="8" fill="url(#statsGradient)" />
    
    {/* Graph Lines */}
    <path
      d="M40 100 L80 70 L120 90 L160 40"
      stroke="#3B82F6"
      strokeWidth="2"
      className="animate-draw"
    />
    
    {/* Data Points */}
    <circle cx="80" cy="70" r="4" fill="#3B82F6" className="animate-pulse" />
    <circle cx="120" cy="90" r="4" fill="#3B82F6" className="animate-pulse" />
    <circle cx="160" cy="40" r="4" fill="#3B82F6" className="animate-pulse" />
  </svg>
);

// Documentation SVG
const DocumentationIllustration = () => (
  <svg
    viewBox="0 0 200 160"
    className="w-full h-full"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <defs>
      <linearGradient id="docsGradient" x1="0" y1="0" x2="1" y2="1">
        <stop offset="0%" stopColor="#3B82F6" stopOpacity="0.1" />
        <stop offset="100%" stopColor="#60A5FA" stopOpacity="0.2" />
      </linearGradient>
    </defs>
    
    {/* Doc Background */}
    <rect x="40" y="20" width="120" height="120" rx="8" fill="url(#docsGradient)" />
    
    {/* Lines */}
    <g className="animate-fade-in" stroke="#3B82F6" strokeWidth="1" strokeLinecap="round">
      <line x1="60" y1="50" x2="140" y2="50" />
      <line x1="60" y1="70" x2="120" y2="70" />
      <line x1="60" y1="90" x2="130" y2="90" />
      <line x1="60" y1="110" x2="110" y2="110" />
    </g>
  </svg>
);

// Blog Card SVG
const BlogIllustration = () => (
  <svg
    viewBox="0 0 200 160"
    className="w-full h-full"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <defs>
      <linearGradient id="blogGradient" x1="0" y1="0" x2="1" y2="1">
        <stop offset="0%" stopColor="#3B82F6" stopOpacity="0.2" />
        <stop offset="100%" stopColor="#60A5FA" stopOpacity="0.1" />
      </linearGradient>
    </defs>
    
    {/* Blog Post Cards */}
    <g className="animate-fade-in delay-2">
      <rect x="20" y="20" width="160" height="40" rx="4" fill="url(#blogGradient)" />
      <rect x="20" y="70" width="160" height="40" rx="4" fill="url(#blogGradient)" />
      <rect x="20" y="120" width="160" height="40" rx="4" fill="url(#blogGradient)" />
    </g>
    
    {/* Post Meta */}
    <g className="animate-pulse" fill="#3B82F6">
      <circle cx="35" cy="40" r="4" />
      <circle cx="35" cy="90" r="4" />
      <circle cx="35" cy="140" r="4" />
    </g>
  </svg>
);

const Resources = () => {
  const navigate = useNavigate();

  const caseStudies = [
    {
      title: "25% Faster Diagnosis Time",
      category: "Healthcare",
      description: "How a leading hospital reduced patient wait times and improved accuracy",
      metrics: ["25% faster diagnosis", "95% accuracy rate", "40% cost reduction"],
      icon: TrendingUp
    },
    {
      title: "Streamlined Patient Care",
      category: "Clinical",
      description: "Improving patient outcomes through AI-driven care management",
      metrics: ["30% better outcomes", "90% patient satisfaction", "50% time saved"],
      icon: Users
    },
    {
      title: "Enhanced Data Security",
      category: "Technology",
      description: "Implementing HIPAA-compliant AI solutions at scale",
      metrics: ["100% HIPAA compliance", "99.99% uptime", "Zero data breaches"],
      icon: Shield
    }
  ];

  const documents = [
    {
      title: "Quick Start Guide",
      description: "Get up and running with JaanchAI platform",
      icon: FileText,
      link: "/docs/quickstart"
    },
    {
      title: "API Documentation",
      description: "Comprehensive API reference and examples",
      icon: Code,
      link: "/docs/api"
    },
    {
      title: "Integration Guide",
      description: "Step-by-step system integration instructions",
      icon: BookOpen,
      link: "/docs/integration"
    }
  ];

  const blogs = [
    {
      title: "The Future of AI in Healthcare",
      date: "Nov 28, 2023",
      category: "Technology",
      excerpt: "Exploring how AI is transforming healthcare delivery and patient outcomes",
      readTime: "5 min read"
    },
    {
      title: "Implementing AI in Clinical Settings",
      date: "Nov 22, 2023",
      category: "Healthcare",
      excerpt: "Best practices for integrating AI solutions in medical facilities",
      readTime: "7 min read"
    },
    {
      title: "Healthcare Data Security",
      date: "Nov 15, 2023",
      category: "Security",
      excerpt: "Ensuring HIPAA compliance while leveraging AI capabilities",
      readTime: "6 min read"
    }
  ];

  return (
    <div className="min-h-screen bg-black">
      {/* Hero Section */}
      <div className="pt-32 pb-16 px-4 sm:px-6 lg:px-8">
        <div className="max-w-7xl mx-auto">
          <div className="text-center">
            <h1 className="text-4xl font-bold tracking-tight text-white sm:text-5xl">
              Resources
              <span className="block mt-2 gradient-text">Knowledge Hub</span>
            </h1>
            <p className="mt-6 text-xl text-gray-400 max-w-2xl mx-auto">
              Explore our comprehensive resources to make the most of JaanchAI
            </p>
          </div>
        </div>
      </div>

      {/* Case Studies Section */}
      <section className="py-16 px-4 sm:px-6 lg:px-8">
        <div className="max-w-7xl mx-auto">
          <div className="text-center mb-12">
            <h2 className="text-3xl font-bold text-white">Case Studies</h2>
            <p className="mt-4 text-gray-400">Real-world success stories from healthcare providers</p>
          </div>

          <div className="grid md:grid-cols-3 gap-8">
            {caseStudies.map((study, index) => (
              <div 
                key={index}
                className="bg-white/5 backdrop-blur-sm rounded-xl p-6 hover:bg-white/10 transition-all duration-300"
              >
                <div className="h-32 mb-6">
                  <CaseStudyIllustration />
                </div>
                <div className="flex items-center gap-2 text-blue-400 text-sm mb-3">
                  <study.icon className="w-4 h-4" />
                  <span>{study.category}</span>
                </div>
                <h3 className="text-xl font-semibold text-white mb-3">{study.title}</h3>
                <p className="text-gray-400 mb-4">{study.description}</p>
                <div className="space-y-2 mb-6">
                  {study.metrics.map((metric, idx) => (
                    <div key={idx} className="flex items-center text-sm text-gray-300">
                      <div className="w-1 h-1 rounded-full bg-blue-500 mr-2" />
                      {metric}
                    </div>
                  ))}
                </div>
                <Link 
                  to={`/case-studies/${study.title.toLowerCase().replace(/\s+/g, '-')}`}
                  className="inline-flex items-center text-blue-400 hover:text-blue-300 transition-colors"
                >
                  Read more <ArrowRight className="ml-2 w-4 h-4" />
                </Link>
              </div>
            ))}
          </div>
        </div>
      </section>

      {/* Documentation Section */}
      <section className="py-16 px-4 sm:px-6 lg:px-8 bg-white/5">
        <div className="max-w-7xl mx-auto">
          <div className="text-center mb-12">
            <h2 className="text-3xl font-bold text-white">Documentation</h2>
            <p className="mt-4 text-gray-400">Everything you need to implement and use JaanchAI</p>
          </div>

          <div className="grid md:grid-cols-3 gap-8">
            {documents.map((doc, index) => (
              <Link
                key={index}
                to={doc.link}
                className="bg-white/5 backdrop-blur-sm rounded-xl p-6 hover:bg-white/10 transition-all duration-300"
              >
                <div className="h-32 mb-6">
                  <DocumentationIllustration />
                </div>
                <div className="flex items-center gap-3 mb-4">
                  <doc.icon className="w-6 h-6 text-blue-400" />
                  <h3 className="text-xl font-semibold text-white">{doc.title}</h3>
                </div>
                <p className="text-gray-400">{doc.description}</p>
                <div className="mt-4 inline-flex items-center text-blue-400 hover:text-blue-300 transition-colors">
                  View documentation <ArrowRight className="ml-2 w-4 h-4" />
                </div>
              </Link>
            ))}
          </div>
        </div>
      </section>

      {/* Blog Section */}
      <section className="py-16 px-4 sm:px-6 lg:px-8">
        <div className="max-w-7xl mx-auto">
          <div className="text-center mb-12">
            <h2 className="text-3xl font-bold text-white">Latest Updates</h2>
            <p className="mt-4 text-gray-400">Insights and news from our team</p>
          </div>

          <div className="grid md:grid-cols-3 gap-8">
            {blogs.map((post, index) => (
              <div 
                key={index}
                className="bg-white/5 backdrop-blur-sm rounded-xl p-6 hover:bg-white/10 transition-all duration-300"
              >
                <div className="h-32 mb-6">
                  <BlogIllustration />
                </div>
                <div className="flex items-center gap-2 text-sm text-gray-400 mb-3">
                  <Calendar className="w-4 h-4" />
                  <span>{post.date}</span>
                  <span className="mx-2">·</span>
                  <span>{post.readTime}</span>
                </div>
                <h3 className="text-xl font-semibold text-white mb-3">{post.title}</h3>
                <p className="text-gray-400 mb-4">{post.excerpt}</p>
                <Link 
                  to={`/blog/${post.title.toLowerCase().replace(/\s+/g, '-')}`}
                  className="inline-flex items-center text-blue-400 hover:text-blue-300 transition-colors"
                >
                  Read post <ArrowRight className="ml-2 w-4 h-4" />
                </Link>
              </div>
            ))}
          </div>
        </div>
      </section>

      {/* CTA Section */}
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-16">
        <div className="relative overflow-hidden bg-gradient-to-r from-blue-600 to-blue-700 rounded-2xl p-8 md:p-16 text-center">
          <div className="relative z-10">
            <h2 className="text-3xl font-bold text-white mb-4">
              Ready to get started?
            </h2>
            <p className="text-blue-100 mb-8 max-w-2xl mx-auto">
              Join leading healthcare providers who are already using JaanchAI.
            </p>
            <button 
            onClick={() => navigate('/demo')}
             className="bg-white text-blue-600 px-8 py-3 rounded-lg font-semibold hover:bg-blue-50 transition-colors">
              Schedule a Demo
            </button>
          </div>
          <div className="absolute inset-0 opacity-10 bg-[radial-gradient(circle_at_30%_50%,rgba(255,255,255,0.2),transparent_50%)]" />
        </div>
      </div>
    </div>
  );
};

export default Resources;