import { useState } from 'react';
import { LineChart, Line, AreaChart, Area, ResponsiveContainer, XAxis, YAxis, Tooltip } from 'recharts';
import { ArrowRight } from 'lucide-react';
import { useNavigate } from 'react-router-dom';

interface UsageMetrics {
  timeframe: string;
  standard: number;
  withAI: number;
  accuracy: number;
  savings: number;
}

interface CaseStudy {
  id: string;
  title: string;
  category: string;
  description: string;
  metrics: UsageMetrics[];
  impact: {
    value: string;
    metric: string;
    comparison: string;
  }[];
  implementation: string[];
}

const CaseStudiesSection = () => {
  const [activeCase, setActiveCase] = useState<string>('diagnosis');
  const navigate = useNavigate();

  const caseStudies: Record<string, CaseStudy> = {
    diagnosis: {
      id: 'diagnosis',
      title: 'Emergency Department Triage',
      category: 'Clinical Decision Support',
      description: 'AI-powered triage system processing patient symptoms and vital signs to prioritize care delivery and reduce wait times.',
      metrics: [
        { timeframe: 'Week 1', standard: 45, withAI: 25, accuracy: 88, savings: 22 },
        { timeframe: 'Week 2', standard: 42, withAI: 22, accuracy: 91, savings: 28 },
        { timeframe: 'Week 3', standard: 44, withAI: 20, accuracy: 94, savings: 32 },
        { timeframe: 'Week 4', standard: 43, withAI: 18, accuracy: 96, savings: 35 },
        { timeframe: 'Week 5', standard: 45, withAI: 16, accuracy: 97, savings: 38 },
        { timeframe: 'Week 6', standard: 44, withAI: 15, accuracy: 98, savings: 40 }
      ],
      impact: [
        { value: '65%', metric: 'Reduction in wait times', comparison: 'vs. traditional triage' },
        { value: '98%', metric: 'Triage accuracy', comparison: 'validated by specialists' },
        { value: '3.2x', metric: 'Faster processing', comparison: 'compared to manual assessment' }
      ],
      implementation: [
        'Automated symptom analysis',
        'Real-time vital sign monitoring',
        'Risk scoring algorithm',
        'Priority-based queuing system'
      ]
    },
    radiology: {
      id: 'radiology',
      title: 'Radiology Analysis',
      category: 'Diagnostic Imaging',
      description: 'Machine learning algorithms analyzing medical imaging to assist in diagnosis and reduce interpretation time.',
      metrics: [
        { timeframe: 'Week 1', standard: 35, withAI: 22, accuracy: 89, savings: 25 },
        { timeframe: 'Week 2', standard: 34, withAI: 20, accuracy: 92, savings: 30 },
        { timeframe: 'Week 3', standard: 33, withAI: 18, accuracy: 94, savings: 35 },
        { timeframe: 'Week 4', standard: 32, withAI: 16, accuracy: 95, savings: 40 },
        { timeframe: 'Week 5', standard: 31, withAI: 15, accuracy: 97, savings: 42 },
        { timeframe: 'Week 6', standard: 30, withAI: 14, accuracy: 98, savings: 45 }
      ],
      impact: [
        { value: '55%', metric: 'Faster analysis time', comparison: 'vs. manual review' },
        { value: '98%', metric: 'Detection accuracy', comparison: 'for common conditions' },
        { value: '40%', metric: 'Cost reduction', comparison: 'in diagnostic process' }
      ],
      implementation: [
        'Automated image analysis',
        'Anomaly detection',
        'Structured reporting',
        'Integration with PACS'
      ]
    },
    pathology: {
      id: 'pathology',
      title: 'Digital Pathology',
      category: 'Laboratory Analysis',
      description: 'Digital scanning and AI analysis of pathology slides for faster and more accurate disease detection.',
      metrics: [
        { timeframe: 'Week 1', standard: 55, withAI: 35, accuracy: 90, savings: 20 },
        { timeframe: 'Week 2', standard: 52, withAI: 32, accuracy: 92, savings: 25 },
        { timeframe: 'Week 3', standard: 50, withAI: 28, accuracy: 94, savings: 30 },
        { timeframe: 'Week 4', standard: 48, withAI: 25, accuracy: 96, savings: 35 },
        { timeframe: 'Week 5', standard: 45, withAI: 22, accuracy: 97, savings: 38 },
        { timeframe: 'Week 6', standard: 42, withAI: 20, accuracy: 98, savings: 40 }
      ],
      impact: [
        { value: '58%', metric: 'Time saved per case', comparison: 'vs. traditional methods' },
        { value: '96%', metric: 'Detection accuracy', comparison: 'for malignancies' },
        { value: '2.8x', metric: 'Throughput increase', comparison: 'in sample processing' }
      ],
      implementation: [
        'Digital slide scanning',
        'AI-powered analysis',
        'Automated measurements',
        'Collaborative review system'
      ]
    }
  };

  return (
    <div className="bg-black text-white">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-24">
        {/* Header */}
        <div className="max-w-3xl mx-auto text-center mb-16">
          <h2 className="text-3xl font-bold tracking-tight">
            Real-World Applications
          </h2>
          <p className="mt-4 text-lg text-gray-400">
            See how AI is transforming different areas of healthcare delivery
          </p>
        </div>

        {/* Case Study Navigation */}
        <div className="flex justify-center space-x-4 mb-16">
          {Object.values(caseStudies).map((study) => (
            <button
              key={study.id}
              onClick={() => setActiveCase(study.id)}
              className={`px-6 py-2 rounded-lg text-sm transition-all duration-200 ${
                activeCase === study.id
                  ? 'bg-white text-black'
                  : 'bg-white/5 text-gray-300 hover:bg-white/10'
              }`}
            >
              {study.title}
            </button>
          ))}
        </div>

        {/* Main Content */}
        <div className="grid lg:grid-cols-2 gap-12">
          {/* Left: Info */}
          <div className="space-y-8">
            <div>
              <div className="text-sm text-blue-400 mb-2">{caseStudies[activeCase].category}</div>
              <h3 className="text-2xl font-bold mb-4">{caseStudies[activeCase].title}</h3>
              <p className="text-gray-400">{caseStudies[activeCase].description}</p>
            </div>

            {/* Impact Metrics */}
            <div className="grid grid-cols-3 gap-4">
              {caseStudies[activeCase].impact.map((item, index) => (
                <div key={index} className="p-4 rounded-lg bg-white/5">
                  <div className="text-2xl font-bold text-blue-400">{item.value}</div>
                  <div className="text-sm text-gray-400 mt-1">{item.metric}</div>
                  <div className="text-xs text-gray-500 mt-1">{item.comparison}</div>
                </div>
              ))}
            </div>

            {/* Implementation Details */}
            <div>
              <h4 className="text-lg font-semibold mb-4">Implementation</h4>
              <div className="grid grid-cols-2 gap-4">
                {caseStudies[activeCase].implementation.map((item, index) => (
                  <div key={index} className="flex items-center gap-2 text-sm text-gray-400">
                    <div className="w-1 h-1 rounded-full bg-blue-400" />
                    {item}
                  </div>
                ))}
              </div>
            </div>
          </div>

          {/* Right: Charts */}
          <div className="space-y-8">
            {/* Processing Time Comparison */}
            <div className="p-6 rounded-lg bg-white/5">
              <h4 className="text-sm font-medium mb-4">Processing Time Comparison</h4>
              <div className="h-64">
                <ResponsiveContainer width="100%" height="100%">
                  <LineChart data={caseStudies[activeCase].metrics}>
                    <XAxis dataKey="timeframe" stroke="#6B7280" />
                    <YAxis stroke="#6B7280" />
                    <Tooltip
                      contentStyle={{
                        backgroundColor: 'rgba(0, 0, 0, 0.8)',
                        border: 'none',
                        borderRadius: '4px'
                      }}
                    />
                    <Line
                      type="monotone"
                      dataKey="standard"
                      stroke="#6B7280"
                      strokeWidth={2}
                      name="Standard Process"
                    />
                    <Line
                      type="monotone"
                      dataKey="withAI"
                      stroke="#3B82F6"
                      strokeWidth={2}
                      name="With AI"
                    />
                  </LineChart>
                </ResponsiveContainer>
              </div>
            </div>

            {/* Accuracy & Savings */}
            <div className="p-6 rounded-lg bg-white/5">
              <h4 className="text-sm font-medium mb-4">Accuracy & Cost Savings</h4>
              <div className="h-64">
                <ResponsiveContainer width="100%" height="100%">
                  <AreaChart data={caseStudies[activeCase].metrics}>
                    <defs>
                      <linearGradient id="colorAccuracy" x1="0" y1="0" x2="0" y2="1">
                        <stop offset="5%" stopColor="#3B82F6" stopOpacity={0.2}/>
                        <stop offset="95%" stopColor="#3B82F6" stopOpacity={0}/>
                      </linearGradient>
                    </defs>
                    <XAxis dataKey="timeframe" stroke="#6B7280" />
                    <YAxis stroke="#6B7280" />
                    <Tooltip
                      contentStyle={{
                        backgroundColor: 'rgba(0, 0, 0, 0.8)',
                        border: 'none',
                        borderRadius: '4px'
                      }}
                    />
                    <Area
                      type="monotone"
                      dataKey="accuracy"
                      stroke="#3B82F6"
                      fill="url(#colorAccuracy)"
                      name="Accuracy %"
                    />
                    <Line
                      type="monotone"
                      dataKey="savings"
                      stroke="#22C55E"
                      strokeWidth={2}
                      name="Cost Savings %"
                    />
                  </AreaChart>
                </ResponsiveContainer>
              </div>
            </div>
          </div>
        </div>

        {/* CTA */}
        <div className="mt-16 text-center">
          <button onClick={() => navigate('/demo')} className="inline-flex items-center px-6 py-3 rounded-lg bg-white text-black hover:bg-gray-100 transition-colors">
            Schedule a Demo
            <ArrowRight className="ml-2 h-4 w-4" />
          </button>
        </div>
      </div>
    </div>
  );
};

export default CaseStudiesSection;