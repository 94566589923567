import { useState, useEffect } from 'react';

const Logo = () => {
  const [mounted, setMounted] = useState(false);

  useEffect(() => {
    setMounted(true);
  }, []);

  return (
    <div className="flex items-center gap-3 select-none">
      <div className="relative w-10 h-10">
        <div className={`transition-opacity duration-500 ${mounted ? 'opacity-100' : 'opacity-0'}`}>
          <svg
            viewBox="0 0 40 40"
            className="w-10 h-10"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <defs>
              <linearGradient
                id="helix-gradient"
                x1="0"
                y1="0"
                x2="40"
                y2="40"
                gradientUnits="userSpaceOnUse"
              >
                <stop offset="0%" stopColor="#93C5FD" />
                <stop offset="50%" stopColor="#3B82F6" />
                <stop offset="100%" stopColor="#2563EB" />
              </linearGradient>
              <linearGradient
                id="glow-gradient"
                x1="0"
                y1="0"
                x2="40"
                y2="40"
                gradientUnits="userSpaceOnUse"
              >
                <stop offset="0%" stopColor="#60A5FA" stopOpacity="0.5" />
                <stop offset="100%" stopColor="#3B82F6" stopOpacity="0" />
              </linearGradient>
            </defs>

            {/* Base Circle with Gradient */}
            <circle
              cx="20"
              cy="20"
              r="16"
              fill="url(#helix-gradient)"
              className="opacity-20"
            />

            {/* Animated DNA Helixes */}
            <g 
              style={{ 
                animation: mounted ? 'spin 3s linear infinite' : 'none',
                transformOrigin: 'center'
              }}
            >
              {/* First Helix */}
              <path
                d="M12 12C12 12 28 16 28 20C28 24 12 28 12 28"
                stroke="url(#helix-gradient)"
                strokeWidth="2"
                strokeLinecap="round"
                className="opacity-80"
              />
              {/* Second Helix */}
              <path
                d="M28 12C28 12 12 16 12 20C12 24 28 28 28 28"
                stroke="url(#helix-gradient)"
                strokeWidth="2"
                strokeLinecap="round"
                className="opacity-80"
              />
            </g>

            {/* Connection Points */}
            <g 
              style={{ 
                animation: mounted ? 'pulse 2s cubic-bezier(0.4, 0, 0.6, 1) infinite' : 'none'
              }}
            >
              <circle cx="20" cy="20" r="2" fill="white" className="opacity-90" />
              <circle cx="12" cy="12" r="1.5" fill="white" className="opacity-70" />
              <circle cx="28" cy="28" r="1.5" fill="white" className="opacity-70" />
            </g>

            {/* Outer Glow Ring */}
            <circle
              cx="20"
              cy="20"
              r="18"
              stroke="url(#glow-gradient)"
              strokeWidth="0.5"
              className="opacity-50"
            />
          </svg>

          {/* Background Glow Effect */}
          <div 
            className={`absolute -inset-2 rounded-full transition-opacity duration-500 ${
              mounted ? 'opacity-50' : 'opacity-0'
            }`}
            style={{
              background: 'radial-gradient(circle, rgba(59,130,246,0.2) 0%, rgba(59,130,246,0) 70%)',
              animation: mounted ? 'glow 3s ease-in-out infinite' : 'none'
            }}
          />
        </div>
      </div>

      {/* Text Part */}
      <div className="flex flex-col">
        <div className="font-mono text-2xl font-bold">
          <span className="text-white">JAANCH</span>
          <span className="text-transparent bg-clip-text bg-gradient-to-r from-blue-400 to-blue-500">.AI</span>
        </div>
        <span className="text-[10px] font-medium text-blue-400/80 tracking-[0.2em]">
          NEXT-GEN HEALTHCARE
        </span>
      </div>
    </div>
  );
};

export default Logo;