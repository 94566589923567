import { useState } from 'react';
import { Brain, ShieldCheck, Microscope, LucideIcon } from 'lucide-react';

interface Feature {
  id: 'diagnostics' | 'research' | 'security';
  icon: LucideIcon;
  title: string;
  description: string;
  stats: {
    [key: string]: number;
  };
  codeSnippet: string;
  benefits: string[];
}

const FeaturesSection = () => {
  const [activeFeature, setActiveFeature] = useState<Feature['id']>('diagnostics');

  const features: Record<Feature['id'], Feature> = {
    diagnostics: {
      id: 'diagnostics',
      icon: Brain,
      title: 'AI-Powered Diagnostics',
      description: 'Advanced machine learning algorithms process patient data to provide accurate diagnostic suggestions.',
      stats: {
        accuracy: 95,
        speed: 25,
        conditions: 2500
      },
      codeSnippet: `
// Example: AI Diagnostic Processing
const diagnosis = await jaanchAI.analyze({
  symptoms: patientData.symptoms,
  medicalHistory: patientData.history,
  labResults: patientData.labs
});

// Accuracy confidence: 95.8%
return {
  suggestedDiagnosis: diagnosis.primary,
  differentials: diagnosis.alternates,
  confidenceScore: diagnosis.confidence
};`,
      benefits: [
        'Reduces diagnosis time by 25%',
        'Covers 2,500+ medical conditions',
        'Continuous learning from new cases',
        'Real-time probability scoring'
      ]
    },
    research: {
      id: 'research',
      icon: Microscope,
      title: 'Clinical Research Integration',
      description: 'Machine learning algorithms analyzing medical research data to assist in evidence-based treatment decisions.',
      stats: {
        papers: 1000000,
        updates: 24,
        accuracy: 98
      },
      codeSnippet: `
// Example: Research Data Integration
const evidence = await jaanchAI.research({
  condition: diagnosis.primary,
  patientProfile: {
    age: patient.age,
    gender: patient.gender,
    comorbidities: patient.conditions
  }
});

// Latest research matched: 98.2%
return {
  recommendedTreatments: evidence.treatments,
  clinicalTrials: evidence.trials,
  latestStudies: evidence.publications
};`,
      benefits: [
        'Access to 1M+ research papers',
        'Daily research updates',
        '98% matching accuracy',
        'Personalized treatment insights'
      ]
    },
    security: {
      id: 'security',
      icon: ShieldCheck,
      title: 'Enterprise Security',
      description: 'HIPAA-compliant platform with end-to-end encryption and advanced access controls.',
      stats: {
        encryption: 256,
        compliance: 100,
        uptime: 99.99
      },
      codeSnippet: `
// Example: Security Implementation
const session = await jaanchAI.createSecureSession({
  userRole: 'physician',
  accessLevel: 'primary',
  encryption: 'AES-256',
  audit: true
});

// Security compliance: 100%
return {
  sessionToken: session.token,
  permissions: session.access,
  auditLog: session.trail
};`,
      benefits: [
        '256-bit encryption',
        '100% HIPAA compliance',
        '99.99% platform uptime',
        'Real-time audit logging'
      ]
    }
  };

  const Feature = features[activeFeature];
  const FeatureIcon = Feature.icon;

  return (
    <div className="min-h-screen bg-gradient-to-b from-gray-50 to-white py-24">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="text-center max-w-3xl mx-auto mb-16">
          <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
            Intelligence Meets Healthcare
          </h2>
          <p className="mt-4 text-lg text-gray-600">
            Powerful features that transform healthcare delivery through artificial intelligence
          </p>
        </div>

        {/* Feature Navigation */}
        <div className="flex justify-center space-x-2 mb-16">
          {Object.values(features).map((feature) => (
            <button
              key={feature.id}
              onClick={() => setActiveFeature(feature.id)}
              className={`
                px-6 py-3 rounded-lg text-sm font-medium transition-all duration-200
                ${activeFeature === feature.id 
                  ? 'bg-black text-white shadow-lg scale-105' 
                  : 'bg-gray-100 text-gray-600 hover:bg-gray-200'
                }
              `}
            >
              {feature.title}
            </button>
          ))}
        </div>

        {/* Feature Content */}
        <div className="grid lg:grid-cols-2 gap-16 items-start">
          {/* Left: Feature Details */}
          <div className="space-y-8">
            <div className="flex items-center gap-4">
              <div className="p-3 rounded-2xl bg-blue-50">
                <FeatureIcon className="w-8 h-8 text-blue-500" />
              </div>
              <h3 className="text-2xl font-semibold text-gray-900">{Feature.title}</h3>
            </div>

            <p className="text-lg text-gray-600 leading-relaxed">
              {Feature.description}
            </p>

            <div className="grid grid-cols-3 gap-4">
              {Object.entries(Feature.stats).map(([key, value]) => (
                <div key={key} className="p-4 rounded-lg bg-gray-50">
                  <div className="text-2xl font-semibold text-gray-900">
                    {typeof value === 'number' && value > 100 ? `${value/1000}k+` : value}
                    {key === 'accuracy' && '%'}
                  </div>
                  <div className="text-sm text-gray-500 capitalize">{key}</div>
                </div>
              ))}
            </div>

            <div className="space-y-4">
              <h4 className="font-medium text-gray-900">Key Benefits</h4>
              <ul className="space-y-3">
                {Feature.benefits.map((benefit) => (
                  <li key={benefit} className="flex items-center gap-3">
                    <div className="h-1.5 w-1.5 rounded-full bg-blue-500" />
                    <span className="text-gray-600">{benefit}</span>
                  </li>
                ))}
              </ul>
            </div>
          </div>

          {/* Right: Interactive Code Demo */}
          <div className="relative group">
            <div className="absolute -inset-0.5 bg-gradient-to-r from-blue-500 to-purple-500 rounded-lg blur opacity-30 group-hover:opacity-100 transition duration-1000 group-hover:duration-200" />
            <div className="relative">
              <div className="bg-black rounded-lg p-4">
                <div className="flex items-center gap-2 mb-4">
                  <div className="w-3 h-3 rounded-full bg-red-500" />
                  <div className="w-3 h-3 rounded-full bg-yellow-500" />
                  <div className="w-3 h-3 rounded-full bg-green-500" />
                </div>
                <pre className="text-sm">
                  <code className="text-gray-300 font-mono whitespace-pre-wrap">
                    {Feature.codeSnippet}
                  </code>
                </pre>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FeaturesSection;