import { useState } from 'react';
import { BarChart, Bar, Cell, XAxis, YAxis, ResponsiveContainer, Tooltip } from 'recharts';

interface MetricItem {
  name: string;
  value: number;
  delta: string;
}

interface MetricData {
  title: string;
  description: string;
  data: MetricItem[];
}

interface Metrics {
  [key: string]: MetricData;
}

const StatsSection = () => {
  const [activeMetric, setActiveMetric] = useState<'impact' | 'efficiency' | 'satisfaction'>('impact');

  const metrics: Metrics = {
    impact: {
      title: "Healthcare Impact",
      description: "Measuring the direct impact on patient care quality",
      data: [
        { name: "Diagnosis Accuracy", value: 95, delta: "+20%" },
        { name: "Patient Outcomes", value: 92, delta: "+15%" },
        { name: "Treatment Speed", value: 88, delta: "+25%" },
        { name: "Care Quality", value: 90, delta: "+18%" }
      ]
    },
    efficiency: {
      title: "Operational Efficiency",
      description: "Resource optimization and time savings",
      data: [
        { name: "Staff Utilization", value: 85, delta: "+30%" },
        { name: "Cost Reduction", value: 78, delta: "-22%" },
        { name: "Time Savings", value: 89, delta: "+35%" },
        { name: "Resource Usage", value: 82, delta: "-25%" }
      ]
    },
    satisfaction: {
      title: "Satisfaction Metrics",
      description: "Patient and staff satisfaction improvements",
      data: [
        { name: "Patient Score", value: 94, delta: "+28%" },
        { name: "Staff Rating", value: 88, delta: "+20%" },
        { name: "Referral Rate", value: 86, delta: "+32%" },
        { name: "Return Visits", value: 91, delta: "+24%" }
      ]
    }
  };

  const getColor = (value: number): string => {
    const colors = ['#94A3B8', '#64748B', '#475569', '#334155'];
    return colors[Math.floor((value / 100) * colors.length)];
  };

  return (
    <div className="bg-white">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-24">
        {/* Section Header */}
        <div className="max-w-2xl mx-auto text-center mb-16">
          <h2 className="text-3xl font-bold tracking-tight text-gray-900">
            Transforming Healthcare Metrics
          </h2>
          <p className="mt-4 text-lg leading-8 text-gray-600">
            Real results from healthcare facilities using JaanchAI
          </p>
        </div>

        {/* Metric Selector */}
        <div className="flex justify-center space-x-4 mb-12">
          {Object.entries(metrics).map(([key, value]) => (
            <button
              key={key}
              onClick={() => setActiveMetric(key as 'impact' | 'efficiency' | 'satisfaction')}
              className={`px-6 py-2 rounded-full text-sm font-medium transition-all duration-200 ${
                activeMetric === key
                  ? 'bg-gray-900 text-white shadow-lg'
                  : 'bg-gray-100 text-gray-600 hover:bg-gray-200'
              }`}
            >
              {value.title}
            </button>
          ))}
        </div>

        <div className="grid lg:grid-cols-5 gap-8">
          {/* Metrics Details */}
          <div className="lg:col-span-2 space-y-8">
            <div>
              <h3 className="text-2xl font-semibold text-gray-900">
                {metrics[activeMetric].title}
              </h3>
              <p className="mt-2 text-gray-600">
                {metrics[activeMetric].description}
              </p>
            </div>

            <div className="space-y-4">
              {metrics[activeMetric].data.map((item) => (
                <div
                  key={item.name}
                  className="group p-4 rounded-lg bg-gray-50 hover:bg-gray-100 transition-all duration-200"
                >
                  <div className="flex justify-between items-center">
                    <span className="text-sm font-medium text-gray-900">
                      {item.name}
                    </span>
                    <span className={`text-sm font-mono ${
                      item.delta.startsWith('+') ? 'text-green-600' : 'text-blue-600'
                    }`}>
                      {item.delta}
                    </span>
                  </div>
                  <div className="mt-2 relative h-2 bg-gray-200 rounded-full overflow-hidden">
                    <div
                      className="absolute left-0 top-0 h-full bg-gray-900 transition-all duration-500"
                      style={{ width: `${item.value}%` }}
                    />
                  </div>
                </div>
              ))}
            </div>
          </div>

          {/* Chart Visualization */}
          <div className="lg:col-span-3 bg-gray-50 rounded-2xl p-8">
            <div className="h-[400px]">
              <ResponsiveContainer width="100%" height="100%">
                <BarChart
                  data={metrics[activeMetric].data}
                  layout="vertical"
                  margin={{ top: 0, right: 0, bottom: 0, left: 100 }}
                >
                  <XAxis type="number" domain={[0, 100]} hide />
                  <YAxis
                    dataKey="name"
                    type="category"
                    scale="band"
                    tick={{ fill: '#4B5563' }}
                    axisLine={false}
                  />
                  <Tooltip
                    cursor={{ fill: '#F3F4F6' }}
                    contentStyle={{
                      backgroundColor: '#ffffff',
                      border: 'none',
                      borderRadius: '8px',
                      boxShadow: '0 4px 6px -1px rgb(0 0 0 / 0.1)',
                    }}
                  />
                  <Bar dataKey="value" radius={[0, 4, 4, 0]}>
                    {metrics[activeMetric].data.map((entry, index) => (
                      <Cell key={`cell-${index}`} fill={getColor(entry.value)} />
                    ))}
                  </Bar>
                </BarChart>
              </ResponsiveContainer>
            </div>
          </div>
        </div>

        {/* Bottom Summary */}
        <div className="mt-16 grid grid-cols-1 md:grid-cols-3 gap-8">
          <div className="text-center">
            <div className="text-4xl font-bold text-gray-900">2.5x</div>
            <div className="mt-2 text-sm text-gray-600">Faster Diagnosis</div>
          </div>
          <div className="text-center">
            <div className="text-4xl font-bold text-gray-900">95%</div>
            <div className="mt-2 text-sm text-gray-600">Accuracy Rate</div>
          </div>
          <div className="text-center">
            <div className="text-4xl font-bold text-gray-900">$2.8M</div>
            <div className="mt-2 text-sm text-gray-600">Average Annual Savings</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default StatsSection;