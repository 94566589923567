import { useState, useEffect } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Menu, X, ChevronDown } from 'lucide-react';
import Logo from './Logo';

interface SubMenuItem {
  name: string;
  href: string;
  description?: string;
}

interface NavItem {
  name: string;
  href: string;
  submenu?: SubMenuItem[];
}

const Header = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);
  const [scrolled, setScrolled] = useState(false);
  const [activeMenu, setActiveMenu] = useState<string | null>(null);

  useEffect(() => {
    const handleScroll = () => {
      setScrolled(window.scrollY > 20);
    };
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  useEffect(() => {
    setIsOpen(false);
    setActiveMenu(null);
  }, [location.pathname]);

  const handleHashLink = (e: React.MouseEvent<HTMLAnchorElement>, href: string) => {
    e.preventDefault();
    const [path, hash] = href.split('#');
    
    if (location.pathname === path && hash) {
      const element = document.getElementById(hash);
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
      }
      setIsOpen(false);
      setActiveMenu(null);
    } else {
      navigate(href);
    }
  };

  const navigation: NavItem[] = [
    {
      name: 'Solutions',
      href: '/solutions',
      submenu: [
        {
          name: 'Clinical Decision Support',
          href: '/solutions#clinical',
          description: 'AI-powered diagnostic assistance and treatment recommendations'
        },
        {
          name: 'Imaging Analysis',
          href: '/solutions#imaging',
          description: 'Advanced medical imaging processing and analysis'
        },
        {
          name: 'Patient Management',
          href: '/solutions#patient-management',
          description: 'Streamlined patient care and monitoring solutions'
        }
      ]
    },
    {
      name: 'Products',
      href: '/products',
      submenu: [
        {
          name: 'JaanchAI Platform',
          href: '/products#platform',
          description: 'Our comprehensive healthcare intelligence platform'
        },
        {
          name: 'API Access',
          href: '/products#api',
          description: 'Integrate AI capabilities into your existing systems'
        },
        {
          name: 'Enterprise Solutions',
          href: '/products#enterprise',
          description: 'Custom solutions for large healthcare organizations'
        }
      ]
    },
    {
      name: 'Resources',
      href: '/resources',
      submenu: [
        {
          name: 'Case Studies',
          href: '/resources#case-studies',
          description: 'Real-world implementation success stories'
        },
        {
          name: 'Documentation',
          href: '/resources#documentation',
          description: 'Technical guides and API documentation'
        },
        {
          name: 'Blog',
          href: '/resources#blog',
          description: 'Insights and updates from our team'
        },
        {
          name: 'Knowledge Base',
          href: '/resources#knowledge-base',
          description: 'FAQs and detailed product information'
        }
      ]
    }
  ];

  const isActive = (href: string) => {
    const [path, hash] = href.split('#');
    if (path === '/') {
      return location.pathname === '/';
    }
    return location.pathname === path || (location.pathname.startsWith(path) && (!hash || location.hash === `#${hash}`));
  };

  const renderSubmenuLink = (subitem: SubMenuItem, onClick?: () => void) => (
    <Link
      key={subitem.name}
      to={subitem.href}
      onClick={(e) => {
        handleHashLink(e, subitem.href);
        onClick?.();
      }}
      className={`block px-4 py-3 hover:bg-white/10 transition-colors rounded-lg ${
        isActive(subitem.href) ? 'bg-white/10' : ''
      }`}
    >
      <div className="text-sm font-medium text-white">{subitem.name}</div>
      {subitem.description && (
        <div className="text-xs text-gray-300 mt-0.5">{subitem.description}</div>
      )}
    </Link>
  );

  return (
    <header 
      className={`fixed w-full z-50 transition-all duration-200 ${
        scrolled || isOpen
          ? 'bg-black border-b border-white/10' 
          : 'bg-transparent'
      }`}
    >
      <nav className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 relative">
        <div className="flex justify-between h-20">
          <div className="flex items-center">
            <Link 
              to="/" 
              className="flex-shrink-0 flex items-center"
              onClick={() => setIsOpen(false)}
            >
              <Logo />
            </Link>
          </div>
          
          {/* Desktop navigation */}
          <div className="hidden md:flex items-center space-x-1">
            {navigation.map((item) => (
              <div key={item.name} className="relative group">
                {item.submenu ? (
                  <button
                    onMouseEnter={() => setActiveMenu(item.name)}
                    onMouseLeave={() => setActiveMenu(null)}
                    className={`px-4 py-2 rounded-lg text-sm font-medium flex items-center gap-1 transition-colors ${
                      isActive(item.href)
                        ? 'text-white bg-white/10'
                        : scrolled
                        ? 'text-gray-300 hover:text-white hover:bg-white/5'
                        : 'text-gray-200 hover:text-white hover:bg-white/5'
                    }`}
                  >
                    {item.name}
                    <ChevronDown className={`w-4 h-4 transition-transform ${
                      activeMenu === item.name ? 'rotate-180' : ''
                    }`} />
                  </button>
                ) : (
                  <Link
                    to={item.href}
                    className={`px-4 py-2 rounded-lg text-sm font-medium transition-colors ${
                      isActive(item.href)
                        ? 'text-white bg-white/10'
                        : scrolled
                        ? 'text-gray-300 hover:text-white hover:bg-white/5'
                        : 'text-gray-200 hover:text-white hover:bg-white/5'
                    }`}
                  >
                    {item.name}
                  </Link>
                )}

                {item.submenu && activeMenu === item.name && (
                  <div
                    onMouseEnter={() => setActiveMenu(item.name)}
                    onMouseLeave={() => setActiveMenu(null)}
                    className="absolute top-full left-0 w-64 mt-1 bg-black border border-white/10 rounded-lg shadow-lg overflow-hidden"
                  >
                    {item.submenu.map((subitem) => renderSubmenuLink(subitem))}
                  </div>
                )}
              </div>
            ))}

            <div className="h-8 w-px bg-white/10 mx-4" />

            <Link
              to="/demo"
              className="px-6 py-2.5 rounded-lg text-sm font-medium 
                bg-gradient-to-r from-blue-600 to-blue-700 
                hover:from-blue-500 hover:to-blue-600 
                text-white shadow-lg shadow-blue-500/25 
                hover:shadow-blue-500/40 
                transform hover:-translate-y-0.5 
                transition-all duration-200"
            >
              Book Demo
            </Link>
          </div>

          {/* Mobile menu button */}
          <div className="md:hidden flex items-center">
            <button
              onClick={() => setIsOpen(!isOpen)}
              className="p-2 rounded-lg text-gray-400 hover:text-white hover:bg-white/5"
              aria-label="Toggle menu"
            >
              {isOpen ? <X className="h-6 w-6" /> : <Menu className="h-6 w-6" />}
            </button>
          </div>
        </div>

        {/* Mobile menu - Dropdown version */}
        {isOpen && (
          <div className="absolute top-20 left-0 right-0 bg-black border-b border-white/10 md:hidden">
            <div className="px-4 py-4 space-y-4 max-h-[calc(100vh-8rem)] overflow-y-auto">
              {navigation.map((item) => (
                <div key={item.name}>
                  {item.submenu ? (
                    <>
                      <button
                        onClick={() => setActiveMenu(activeMenu === item.name ? null : item.name)}
                        className={`w-full flex items-center justify-between px-4 py-2 text-base font-medium rounded-lg ${
                          isActive(item.href)
                            ? 'text-white bg-white/10'
                            : 'text-white hover:bg-white/10'
                        }`}
                      >
                        {item.name}
                        <ChevronDown className={`w-4 h-4 transition-transform ${
                          activeMenu === item.name ? 'rotate-180' : ''
                        }`} />
                      </button>

                      {activeMenu === item.name && (
                        <div className="mt-2 ml-4 space-y-2 border-l-2 border-white/10 pl-4">
                          {item.submenu.map((subitem) => (
                            <Link
                              key={subitem.name}
                              to={subitem.href}
                              onClick={(e) => {
                                handleHashLink(e, subitem.href);
                                setIsOpen(false);
                              }}
                              className="block px-4 py-3 rounded-lg hover:bg-white/10"
                            >
                              <div className="text-sm font-medium text-white">
                                {subitem.name}
                              </div>
                              {subitem.description && (
                                <div className="text-xs text-gray-300 mt-0.5">
                                  {subitem.description}
                                </div>
                              )}
                            </Link>
                          ))}
                        </div>
                      )}
                    </>
                  ) : (
                    <Link
                      to={item.href}
                      className={`block px-4 py-2 text-base font-medium rounded-lg ${
                        isActive(item.href)
                          ? 'text-white bg-white/10'
                          : 'text-white hover:bg-white/10'
                      }`}
                      onClick={() => setIsOpen(false)}
                    >
                      {item.name}
                    </Link>
                  )}
                </div>
              ))}

              <div className="pt-2 pb-4">
                <Link
                  to="/demo"
                  className="block w-full px-6 py-3.5 rounded-lg text-center text-sm font-medium 
                    bg-gradient-to-r from-blue-600 to-blue-700 
                    hover:from-blue-500 hover:to-blue-600 
                    text-white shadow-lg shadow-blue-500/25 
                    hover:shadow-blue-500/40 
                    transition-all duration-200"
                  onClick={() => setIsOpen(false)}
                >
                  Book Demo
                </Link>
              </div>
            </div>
          </div>
        )}
      </nav>
    </header>
  );
};

export default Header;