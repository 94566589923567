import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { ArrowRight } from 'lucide-react';

// Clinical Analysis SVG Component
const ClinicalAnalysisSVG = () => (
  <svg
    viewBox="0 0 400 300"
    className="w-full h-full"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    {/* Gradient Definitions */}
    <defs>
      <linearGradient id="brain-gradient" x1="0" y1="0" x2="1" y2="1">
        <stop offset="0%" stopColor="#60A5FA" stopOpacity="0.2" />
        <stop offset="100%" stopColor="#3B82F6" stopOpacity="0.4" />
      </linearGradient>
      <linearGradient id="scan-gradient" x1="0" y1="0" x2="0" y2="1">
        <stop offset="0%" stopColor="#3B82F6" stopOpacity="0" />
        <stop offset="50%" stopColor="#3B82F6" stopOpacity="0.3" />
        <stop offset="100%" stopColor="#3B82F6" stopOpacity="0" />
      </linearGradient>
    </defs>

    {/* Brain Base Shape */}
    <path
      d="M200 50C140 50 90 100 90 160C90 220 140 270 200 270C260 270 310 220 310 160C310 100 260 50 200 50Z"
      fill="url(#brain-gradient)"
      className="animate-pulse-slow"
    />

    {/* Neural Network */}
    <g className="animate-draw">
      {/* Connection Lines */}
      <path
        d="M150 120 L200 160 M250 120 L200 160 M175 100 L200 160 M225 100 L200 160"
        stroke="#3B82F6"
        strokeWidth="1"
        strokeLinecap="round"
      />
      <path
        d="M200 160 L175 220 M200 160 L225 220"
        stroke="#3B82F6"
        strokeWidth="1"
        strokeLinecap="round"
      />
    </g>

    {/* Neural Points */}
    <g className="animate-pulse">
      <circle cx="150" cy="120" r="4" fill="#3B82F6" />
      <circle cx="250" cy="120" r="4" fill="#3B82F6" />
      <circle cx="175" cy="100" r="4" fill="#3B82F6" />
      <circle cx="225" cy="100" r="4" fill="#3B82F6" />
      <circle cx="200" cy="160" r="6" fill="#3B82F6" />
      <circle cx="175" cy="220" r="4" fill="#3B82F6" />
      <circle cx="225" cy="220" r="4" fill="#3B82F6" />
    </g>

    {/* Scanning Effect */}
    <rect
      x="90"
      y="50"
      width="220"
      height="220"
      fill="url(#scan-gradient)"
      className="animate-scan"
    />

    {/* Data Points */}
    <g className="animate-fade-in delay-2">
      <text x="120" y="140" fill="#60A5FA" fontSize="10">95%</text>
      <text x="260" y="140" fill="#60A5FA" fontSize="10">87%</text>
      <text x="200" y="240" fill="#60A5FA" fontSize="10">92%</text>
    </g>

    {/* Pulse Rings */}
    <circle 
      cx="200" 
      cy="160" 
      r="15" 
      stroke="#3B82F6" 
      strokeWidth="1" 
      className="animate-ping"
      opacity="0.3"
    />
  </svg>
);

// Imaging Analysis SVG Component
const ImagingAnalysisSVG = () => (
  <svg
    viewBox="0 0 400 300"
    className="w-full h-full"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <defs>
      <linearGradient id="xray-gradient" x1="0" y1="0" x2="1" y2="1">
        <stop offset="0%" stopColor="#60A5FA" stopOpacity="0.1" />
        <stop offset="100%" stopColor="#3B82F6" stopOpacity="0.3" />
      </linearGradient>
      <pattern id="grid" x="0" y="0" width="20" height="20" patternUnits="userSpaceOnUse">
        <path 
          d="M 20 0 L 0 0 0 20" 
          stroke="#3B82F6" 
          strokeWidth="0.5" 
          opacity="0.2"
        />
      </pattern>
    </defs>

    {/* Background Frame */}
    <rect
      x="50"
      y="50"
      width="300"
      height="200"
      fill="url(#xray-gradient)"
      className="animate-pulse-slow"
    />
    <rect
      x="50"
      y="50"
      width="300"
      height="200"
      fill="url(#grid)"
      className="animate-fade-in"
    />

    {/* Medical Image */}
    <g className="animate-draw delay-2">
      {/* Lung Outlines */}
      <path
        d="M150 80C150 80 180 100 180 150C180 200 150 220 150 220"
        stroke="#3B82F6"
        strokeWidth="2"
        strokeLinecap="round"
        fill="none"
      />
      <path
        d="M250 80C250 80 220 100 220 150C220 200 250 220 250 220"
        stroke="#3B82F6"
        strokeWidth="2"
        strokeLinecap="round"
        fill="none"
      />
    </g>

    {/* Analysis Points */}
    <g className="animate-pulse">
      <circle cx="180" cy="130" r="5" fill="#10B981" fillOpacity="0.5" />
      <circle cx="220" cy="130" r="5" fill="#10B981" fillOpacity="0.5" />
      <text x="170" y="120" fill="#10B981" fontSize="10">98.5%</text>
      <text x="210" y="120" fill="#10B981" fontSize="10">97.2%</text>
    </g>

    {/* Scanning Line */}
    <line
      x1="50"
      y1="150"
      x2="350"
      y2="150"
      stroke="#3B82F6"
      strokeWidth="2"
      strokeDasharray="5,5"
      className="animate-scan"
    />

    {/* Analysis Data */}
    <g className="animate-fade-in delay-4">
      <rect x="60" y="60" width="80" height="20" fill="#3B82F6" fillOpacity="0.1" />
      <text x="70" y="74" fill="#3B82F6" fontSize="10">Analyzing...</text>
      <rect x="260" y="60" width="80" height="20" fill="#10B981" fillOpacity="0.1" />
      <text x="270" y="74" fill="#10B981" fontSize="10">Match: 99%</text>
    </g>
  </svg>
);

// Patient Management SVG Component
const PatientManagementSVG = () => (
  <svg
    viewBox="0 0 400 300"
    className="w-full h-full"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <defs>
      <linearGradient id="vitals-gradient" x1="0" y1="0" x2="1" y2="1">
        <stop offset="0%" stopColor="#3B82F6" stopOpacity="0.2" />
        <stop offset="100%" stopColor="#60A5FA" stopOpacity="0.1" />
      </linearGradient>
      <filter id="glow">
        <feGaussianBlur stdDeviation="2" result="coloredBlur"/>
        <feMerge>
          <feMergeNode in="coloredBlur"/>
          <feMergeNode in="SourceGraphic"/>
        </feMerge>
      </filter>
    </defs>

    {/* Background Grid */}
    <path
      d="M0 150 H400 M100 0 V300 M200 0 V300 M300 0 V300"
      stroke="#3B82F6"
      strokeWidth="0.5"
      strokeOpacity="0.1"
    />

    {/* ECG Line */}
    <path
      className="animate-draw"
      d="M50 150 
         L100 150 
         L120 150 
         L130 100 
         L140 200 
         L150 150 
         L170 150 
         L200 150
         L220 150 
         L230 100 
         L240 200 
         L250 150 
         L270 150 
         L350 150"
      stroke="#3B82F6"
      strokeWidth="2"
      filter="url(#glow)"
    />

    {/* Vital Signs Monitors */}
    <g className="animate-fade-in delay-2">
      {/* Heart Rate */}
      <rect x="50" y="50" width="80" height="50" rx="5" fill="url(#vitals-gradient)" />
      <text x="65" y="75" fill="#3B82F6" fontSize="18" fontFamily="monospace">72</text>
      <text x="65" y="90" fill="#3B82F6" fontSize="10">BPM</text>

      {/* Blood Pressure */}
      <rect x="160" y="50" width="80" height="50" rx="5" fill="url(#vitals-gradient)" />
      <text x="175" y="75" fill="#3B82F6" fontSize="18" fontFamily="monospace">120/80</text>
      <text x="175" y="90" fill="#3B82F6" fontSize="10">mmHg</text>

      {/* Oxygen Level */}
      <rect x="270" y="50" width="80" height="50" rx="5" fill="url(#vitals-gradient)" />
      <text x="285" y="75" fill="#3B82F6" fontSize="18" fontFamily="monospace">98%</text>
      <text x="285" y="90" fill="#3B82F6" fontSize="10">SpO2</text>
    </g>

    {/* Status Indicators */}
    <g className="animate-fade-in delay-4">
      <circle cx="90" cy="200" r="5" fill="#10B981" className="animate-pulse"/>
      <text x="105" y="205" fill="#10B981" fontSize="12">Status: Normal</text>
      
      <circle cx="200" cy="200" r="5" fill="#10B981" className="animate-pulse"/>
      <text x="215" y="205" fill="#10B981" fontSize="12">AI Monitoring</text>
      
      <circle cx="310" cy="200" r="5" fill="#10B981" className="animate-pulse"/>
      <text x="325" y="205" fill="#10B981" fontSize="12">Data Updated</text>
    </g>

    {/* Alert System */}
    <g className="animate-fade-in delay-6">
      <rect x="150" y="230" width="100" height="30" rx="5" fill="#3B82F6" fillOpacity="0.1"/>
      <text x="165" y="250" fill="#3B82F6" fontSize="12">Risk Score: Low</text>
    </g>
  </svg>
);


const Solutions = () => {
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (location.hash) {
      const element = document.querySelector(location.hash);
      if (element) {
        setTimeout(() => {
          element.scrollIntoView({ behavior: 'smooth' });
        }, 100);
      }
    }
  }, [location]);

  const solutions = [
    {
      id: 'clinical',
      title: "Clinical Decision Support",
      description: "AI-powered diagnostic assistance with real-time recommendations and pattern recognition for improved clinical outcomes.",
      features: [
        "25% faster diagnosis time with AI assistance",
        "95% accuracy in pattern recognition",
        "Real-time clinical guidelines integration",
        "Risk assessment and early warning system"
      ],
      illustration: <ClinicalAnalysisSVG />
    },
    {
      id: 'imaging',
      title: "Medical Imaging Analysis",
      description: "Advanced AI algorithms for rapid and accurate analysis of medical imaging data across multiple modalities.",
      features: [
        "99% detection rate for common conditions",
        "Real-time anomaly detection",
        "Support for X-Ray, MRI, CT, and Ultrasound",
        "Automated reporting and analysis"
      ],
      illustration: <ImagingAnalysisSVG />
    },
    {
      id: 'patient-management',
      title: "Patient Care Management",
      description: "Comprehensive patient monitoring system with predictive analytics and real-time vital sign tracking.",
      features: [
        "24/7 automated patient monitoring",
        "Early warning system for complications",
        "Predictive analytics for patient outcomes",
        "Integrated care coordination"
      ],
      illustration: <PatientManagementSVG />
    }
  ];

  return (
    <div className="min-h-screen bg-black">
      {/* Hero Section */}
      <div className="pt-32 pb-16 px-4 sm:px-6 lg:px-8">
        <div className="max-w-7xl mx-auto">
          <div className="text-center">
            <h1 className="text-4xl font-bold tracking-tight text-white sm:text-5xl">
              AI-Powered Healthcare
              <span className="block mt-2 gradient-text">Solutions</span>
            </h1>
            <p className="mt-6 text-xl text-gray-400 max-w-2xl mx-auto">
              Transform healthcare delivery with intelligent automation and advanced analytics
            </p>
          </div>
        </div>
      </div>

      {/* Solutions Sections */}
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-16">
        <div className="space-y-32">
          {solutions.map((solution) => (
            <section
              key={solution.id}
              id={solution.id}
              className="scroll-mt-24"
            >
              <div className="grid lg:grid-cols-2 gap-16 items-center">
                <div className="space-y-8">
                  <div>
                    <h2 className="text-3xl font-bold text-white mb-4">
                      {solution.title}
                    </h2>
                    <p className="text-xl text-gray-400">
                      {solution.description}
                    </p>
                  </div>
                  <ul className="space-y-4">
                    {solution.features.map((feature, index) => (
                      <li key={index} className="flex items-center text-gray-300">
                        <div className="w-1.5 h-1.5 rounded-full bg-blue-500 mr-2" />
                        {feature}
                      </li>
                    ))}
                  </ul>
                  <button
                    onClick={() => navigate('/demo')}
                    className="inline-flex items-center px-6 py-3 rounded-lg bg-blue-600 text-white hover:bg-blue-700 transition-colors"
                  >
                    Learn more
                    <ArrowRight className="ml-2 h-4 w-4" />
                  </button>
                </div>
                <div className="glass rounded-2xl p-8 h-[400px] hover:bg-white/10 transition-colors">
                  {solution.illustration}
                </div>
              </div>
            </section>
          ))}
        </div>
      </div>

      {/* CTA Section */}
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-16">
        <div className="relative overflow-hidden bg-gradient-to-r from-blue-600 to-blue-700 rounded-2xl p-8 md:p-16 text-center">
          <div className="relative z-10">
            <h2 className="text-3xl font-bold text-white mb-4">
              Ready to transform your healthcare delivery?
            </h2>
            <p className="text-blue-100 mb-8 max-w-2xl mx-auto">
              Join leading healthcare providers who are already experiencing the benefits of AI-powered healthcare solutions.
            </p>
            <button className="bg-white text-blue-600 px-8 py-3 rounded-lg font-semibold hover:bg-blue-50 transition-colors">
              Schedule a Demo
            </button>
          </div>
          <div className="absolute inset-0 opacity-10 bg-[radial-gradient(circle_at_30%_50%,rgba(255,255,255,0.2),transparent_50%)]" />
        </div>
      </div>
    </div>
  );
};

export default Solutions;
